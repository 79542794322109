<template>
<div class="container">
  <div id="app">
    <div id="nav">
<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <router-link class='navbar-item' to="/"><span class="logo">sigilu</span></router-link>

    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onclick="document.querySelector('.navbar-menu').classList.toggle('is-active');">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <router-link class='navbar-item' to="/">Home</router-link>
      <router-link class='navbar-item' to="/about">About</router-link> 
      <router-link class='navbar-item' v-if="$auth.isAuthenticated" to="/create">Create</router-link>
      <router-link class='navbar-item' v-if="$auth.isAuthenticated" to="/profile">Profile</router-link>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <button class="button is-primary" v-if="! $auth.isAuthenticated" @click="login">Log in</button>
          <button class="button" v-if="$auth.isAuthenticated" @click="logout">Log out</button>
        </div>
      </div>
    </div>
  </div>
</nav>
    </div>
    <router-view/>
  </div>
</div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

span.logo {
  color: #027943;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import 'bulma/css/bulma.css'

export default {
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

