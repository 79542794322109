<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />-->
    
    <MyCarAds v-if="$auth.isAuthenticated" />
    
  </div>
</template>
<script>

export default {
  name: "home",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

