<template>
<div>
<form id="createad" @submit.prevent="processForm">
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Car</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="year" placeholder="Year">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="make" placeholder="Make">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="model" placeholder="Model">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="trim" placeholder="Trim">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label"></div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="transmission" placeholder="Transmission">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
     </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="fuel" placeholder="Fuel Type">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
     </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="vin" placeholder="VIN">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
     </p>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Colors</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="colorext" placeholder="Exterior">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="colorint" placeholder="Interior">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="material" placeholder="Material (Leather/Fabric)">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
  </div>
</div>
<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Price</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded has-icons-right">
        <input class="input" type="text" v-model="price" placeholder="$ USD">
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span>
      </p>
    </div>
  </div>
</div>

<div class="field is-horizontal">
  <div class="field-label">
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <button type="submit" class="button is-primary"> Create </button>
      </div>
    </div>
  </div>
</div>
</form>
</div>
</template>
<script>
export default {
  data: function() { 
   return {
    year: '',
    make: '',
    model: '',
    trim: '',
    transmission: '',
    fuel: '',
    vin: '',
    colorext: '',
    colorint: '',
    material: '',
    price: ''}; 
  },
  methods : {
    processForm: function () {
      const datum = {
        year: this.year,
        make: this.make,
        model: this.model,
        trim: this.trim,
        transmission: this.transmission,
        fuel: this.fuel,
        vin: this.vin,
        colorext: this.colorext,
        colorint: this.colorint,
        material: this.material,
        price: this.price,
        status: "new",
        user: this.$auth.user.email};
      console.log(datum);
      this.axios.post("https://s83as3mw9a.execute-api.us-east-1.amazonaws.com/dev/api?method=createad", datum, {headers: {'X-Api-Key': 'TgORJAChhN1aRaosoTKUB8Oz94EhY47N8iWnuEXg', 'Content-Type': 'application/json'}}).then(resp => { this.$router.push('/'); console.log(resp); });
    }
  }
};
</script>
