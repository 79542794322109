<template>
<div>
    <h2>My Car Ads</h2>
    <br/>
    <div id='mycars'>
       <div v-for="c in cars" :key="c.id">
         <div class="columns">
           <div class="column">{{c.ts}}</div>
           <div class="column">{{c.status}}</div>
           <div class="column">{{c.year}} {{c.make}} {{c.model}}</div>
           <div class="column">${{c.price}}</div>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyCarAds',
  data: function () {
    return { cars: [] };
  },
  created: function () {
    this.getCars();
  }, 
  methods: {
    getCars: async function() {
      const datum = { "user" : this.$auth.user.email };
      return this.axios.post("https://s83as3mw9a.execute-api.us-east-1.amazonaws.com/dev/api?method=getMyCars", datum, {headers: {'X-Api-Key': 'TgORJAChhN1aRaosoTKUB8Oz94EhY47N8iWnuEXg', 'Content-Type': 'application/json'}}).then(
         (response) => {
            var arr = response.data.data;
            arr.forEach( function(d) {
              d.ts = new Date(d.ts / 1000);
              d.ts = d.ts.toLocaleString('en-US', { month:'numeric', day:'numeric', year:'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
            });
            this.cars = response.data.data ;
         } ); 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
