import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import MyCarAds from "./components/MyCarAds.vue"


// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

var ayldebug;

// Import the plugin here
import { Auth0Plugin } from "./auth";
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueAxios, axios)
Vue.component("MyCarAds", MyCarAds)

Vue.config.productionTip = false

ayldebug = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
console.log(ayldebug)
